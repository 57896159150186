.QR_main{
    width: 100%;
}

.IconAdd{
    font-size: 32px !important;
}

.btn-add-nuevo{
    font-size: 28px;
    margin-top: 30px !important;
    margin-right: 15px !important;
}

.iconos_div{
    display: contents;
    cursor: pointer;
    color: #00ff85;
    /* color: #008947; */
}

.archivo_muestra{
    color: #CC0000;
    font-size: 14px !important;
}

.archivo_muestra:hover{
    color: #990000;
    font-size: 14px !important;
}

