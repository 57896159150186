.App {
    width: 100vw;
    display: flex;
    justify-content: center;
    font-family: "Poppins", sans-serif;
  }
  body{
    margin: 0px !important;
  }
  
  .container_wc {
    width: 100vw;
    height: 100vh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  img,
  video {
    border-radius: 0px;
    width: 100vw;
  }
  
  .controls {
    display: flex;
    gap: 1px;
  }
  
  .btn-container {
    display: flex;
    gap: 1px;
    z-index: 1000;
    width: 100%;
    align-items: center;
  }
  
  .btn-container button {
    cursor: pointer;
    display: inline-block;
    outline: 0;
    border: none;
    padding: 16px 56px;
    border-radius: 8px;
    background-color: #0e6633;
    color: white;
    font-size: 16px;
    box-shadow: 0 4px 14px 0 rgb(0 118 255 / 39%);
    transition: background 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
    width: 100%;
  }
  
  .btn-container button:hover {
    background: rgba(0, 118, 255, 0.9);
    box-shadow: 0 6px 20px rgb(0 118 255 / 23%);
  }
  .alert{
    margin-top: 100px;
  }
  
  .div_alert{
    margin-top: 100px !important;
  }
  
  .btn_play{
    width: 100px;
    height: 100px; 
    margin: auto;
    color: #FFF;
    justify-content: center;
    justify-self: center;
    position: absolute;
  }
  
  .cortina{
    position: absolute;
    background: #000;
    width: 100%;
    height: 100vh;
    text-align: center;
  }
  
  .btn_retake{
    margin-top: 100px;
  }
  
  .video_sitio{
    width: 100%;
  }
  
  
  .loading-icon {
    color: rgb(118, 23, 41);
    font-size: 6rem;
    animation: animate 2s infinite;
    margin-top: 100px;
  }
  
  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }