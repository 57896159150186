body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lector{
  height: 100vh !important;
  width: 50% !important;
}

.none-div{
  border-bottom: 0px !important;
}

.titulo_soft{
  font-size: 18px;
}

.label_soft{
  font-size: 14px;
}

.input_soft{
  font-size: 13px !important;
}

.div_loading{
  background-color:  rgba(0,0,0, .7);
  position: relative;
  z-index: 100;
  height: 100vh;
  width: 100%;
  color: #FFF;
  text-align: center;
  align-content: center;
  font-size: 36px;
  font-family: Arial;
}

.encabezado_tabla{
  font-size: 13px;
  font-weight: 800;
}

.data_tabla{
  font-size: 13px;
}

.btn_buscar{
  background-color: #212529 !important;
    color: #00ff85 !important;
    border: 0px !important;
}

.icono_listado{
  font-size: 20px !important;
  cursor: pointer;
}

.btn_right{
    margin-left: auto !important;
    display: flex !important;
    margin-right: 0 !important;
}