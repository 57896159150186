@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.logo-top{
    width: 100px;
  }

body{
    font-family: "Inter", sans-serif !important;
    letter-spacing: -.6px;
}

